import { isNotNil } from "@app/helpers/is-not-nil";
import { isRecordObject } from "@app/utils/is-record";

interface ParsedQs {
  [key: string]: undefined | string | string[] | ParsedQs | ParsedQs[];
}

function recursiveParams(
  object: undefined | string | string[] | ParsedQs | ParsedQs[],
  prefix = ""
): string[] {
  const params: string[] = [];

  if (!object) {
    return params;
  }

  return Object.entries(object).flatMap(([key, value]) => {
    const param_name = prefix ? `${prefix}[${key}]` : key;
    if (!isNotNil(value)) {
      return [];
    }

    if (isRecordObject(value)) {
      return recursiveParams(value, param_name);
    }

    return [
      `${encodeURIComponent(param_name)}=${encodeURIComponent(`${value}`)}`
    ];
  });
}

export function computeQueryString(query: ParsedQs | undefined): string {
  if (!query) {
    return "";
  }

  const null_removed = Object.fromEntries(
    Object.entries(query).filter(entry => isNotNil(entry[1]))
  );

  const params = recursiveParams(null_removed).join("&");
  return params ? "?" + params : "";
}

export function interpolateString(template: string, data: unknown): string {
  return template.replace(/\{([^}]+)\}/g, function (_match, keypath) {
    return new Function("_", "return _." + keypath.trim())(data);
  });
}

export const UI_STATUS = {
  pending: "pending",
  success: "success",
  error: "error",
  ready: "ready"
};

export function safeJsonStringify(obj: unknown): string | null {
  return obj ? JSON.stringify(obj) : null;
}
