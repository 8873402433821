import { MergeWhitelabelConfig } from "../default";
import { PartnerConfig } from "../partner.type";

export const config: PartnerConfig = {
  // Unique identifier for partner
  key: "billetesbus",
  // Query string value used to identify a whitelabel
  whitelabel: "billetesbus",
  // Settings used to determine whitelabel behavior
  // IMPORTANT: This will be also be sent to the browser,
  // so do not use it to store any secrets
  whitelabel_settings: MergeWhitelabelConfig({
    busbud_checkout: true,
    billing_address_form: true,
    features_override: {
      DISPLAY_NAME: "Billetesbus"
    },
    utm_home: {
      utm_source: "billetesbus",
      utm_medium: "cpa",
      // Use `String.replace()` to inject locale
      utm_campaign: "partner-billetesbus-tab-{locale}-home"
    },
    utm_checkout: {
      utm_source: "billetesbus",
      utm_medium: "cpa",
      utm_campaign: "partner-billetesbus-whitelabel-{locale}-checkout"
    }
  }),
  // Map all of partner's locales to a Busbud locale, when available
  // If no matching locale, will fallback to default locale
  locale_mapping: {
    "es-ES": "es"
  },
  fallback_locale: "es",
  referral_banner: {
    color: "#ffffff",
    name: "Billetes Bus",
    logo: "https://busbud.imgix.net/referral-partner-logos/billetesbus-1.png?auto=format,compress"
  },
  webpack_config: {
    supported_locales: []
  }
};
