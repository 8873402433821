import { LiteLinkTemplateType } from "@config/link-template-type";
import { SupportedLocale } from "@config/locales";

export default class LiteUrlBuilderService {
  private templates: LiteLinkTemplateType;
  public locale: SupportedLocale;

  public constructor(options: {
    templates: LiteLinkTemplateType;
    locale: SupportedLocale;
  }) {
    this.locale = options.locale;
    this.templates = options.templates;
  }

  private _objectToQuery(obj: { [k: string]: string | number }): string {
    return Object.keys(obj)
      .map(key => {
        return `${key}=${encodeURIComponent(obj[key])}`;
      })
      .join("&");
  }

  public image(
    path: string,
    query?: { [k: string]: string | number } | undefined
  ): string {
    const uri = new URL(path, this.templates.image);
    uri.search = this._objectToQuery({ ...query, auto: "compress,format" });
    return uri.href;
  }
}
