import { MergeWhitelabelConfig } from "../default";
import { PartnerConfig } from "../partner.type";

export const config: PartnerConfig = {
  // Unique identifier for partner
  key: "undostres",
  // Query string value used to identify a whitelabel
  whitelabel: "undostres",
  // Settings used to determine whitelabel behavior
  // IMPORTANT: This will be also be sent to the browser,
  // so do not use it to store any secrets
  whitelabel_settings: MergeWhitelabelConfig({
    hero_image: false,
    billing_address_form: false,
    currency: "MXN",
    shared_information: {
      algorithm: "aes-128-cbc",
      qs: "udtref",
      encryption_key_name: "PARTNER_UNDOSTRES_ENCRYPTION_KEY"
    }
  }),
  fallback_locale: "es",
  webpack_config: {
    supported_locales: []
  }
};
