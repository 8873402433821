import { MergeWhitelabelConfig } from "../default";
import { PartnerConfig } from "../partner.type";

export const config: PartnerConfig = {
  // Unique identifier for whitelabel
  key: "flighthub",
  // Query string value used to identify a whitelabel
  whitelabel: "flighthub",
  // Settings used to determine whitelabel behavior
  // IMPORTANT: This will be also be sent to the browser,
  // so do not use it to store any secrets
  whitelabel_settings: MergeWhitelabelConfig({
    napi_hosts: {
      "flighthub.busbud.com": "flighthub.busbud.com/napi"
    },
    features_override: {
      DISPLAY_NAME: "FlightHub",
      RESULTS_HIDE_AD_BANNERS: true
    }
  }),
  // Map all of partner's locales to a Busbud locale, when available
  // If no matching locale, will fallback to default locale
  locale_mapping: {},
  fallback_locale: "en",
  webpack_config: {
    theme_import: () =>
      import(
        /* webpackChunkName: "theme-flighthub" */
        "@busbud/design-system-components/dist/themes/flighthub"
      ),
    supported_locales: []
  }
};
