import { MergeWhitelabelConfig } from "../default";
import { PartnerConfig } from "../partner.type";

export const config: PartnerConfig = {
  // Unique identifier for partner
  key: "skyscanner",
  // Query string value used to identify a whitelabel
  whitelabel: "skyscanner",
  // Settings used to determine whitelabel behavior
  // IMPORTANT: This will be also be sent to the browser,
  // so do not use it to store any secrets
  whitelabel_settings: MergeWhitelabelConfig({
    hero_image: true,
    busbud_checkout: true,
    billing_address_form: true,
    features_override: {
      DISPLAY_NAME: "Skyscanner"
    },
    utm_home: {
      utm_source: "skyscanner",
      utm_medium: "cpa",
      // Use `String.replace()` to inject locale
      utm_campaign: "partner-skyscanner-tab-{locale}-home"
    },
    utm_checkout: {
      utm_source: "skyscanner",
      utm_medium: "cpa",
      utm_campaign: "partner-skyscanner-whitelabel-{locale}-checkout"
    }
  }),
  // Map all of partner's locales to a Busbud locale, when available
  // If no matching locale, will fallback to default locale
  locale_mapping: {
    "cs-CZ": null,
    "da-DK": null,
    "de-DE": "de",
    "el-GR": null,
    "en-GB": "en-gb",
    "en-US": "en",
    "es-ES": "es",
    "es-MX": "es-mx",
    "fi-FI": null,
    "fr-FR": "fr",
    "hu-HU": null,
    "id-ID": null,
    "it-IT": "it",
    "ja-JP": null,
    "ko-KR": null,
    "ms-MY": null,
    "nb-NO": null,
    "nl-NL": "nl",
    "pl-PL": "pl",
    "pt-BR": "pt",
    "pt-PT": null,
    "ro-RO": null,
    "ru-RU": null,
    "sv-SE": "sv",
    "th-TH": null,
    "tl-PH": null,
    "tr-TR": "tr",
    "uk-UA": null,
    "vi-VN": null,
    "zh-CN": "zh",
    "zh-TW": null
  },
  fallback_locale: "en",
  referral_banner: {
    color: "#5be2ed",
    name: "Skyscanner",
    logo: "https://busbud.imgix.net/referral-partner-logos/skyscanner.png?auto=format,compress"
  },
  webpack_config: {
    supported_locales: []
  }
};
