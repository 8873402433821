import { PartnerConfig } from "./partner.type";
import { WhitelabelFeatures } from "../app/types/experiments";

export const default_whitelabel_features: WhitelabelFeatures = {
  ADULT_ONLY_SEARCH: false,
  ASYNC_CART_SHOULD_HIDE_DISCOUNT_CODE: false,
  ASYNC_CART_SHOULD_HIDE_TERMS_AND_CONDITIONS: false,
  ASYNC_CART_SINGLE_PAGE_PAYMENT_ENABLED: false,
  AUTO_SELECT_ID_TYPE: [],
  BILLING_ADDRESS_FORM: false,
  CANCELLATION_WITHOUT_ACCOUNT_ENABLED: false,
  CHECKOUT_SEAT_SELECTION_V2_SHOULD_SHOW_SEAT_SELECTOR_DRAWER_TITLE: true,
  CHECKOUT_SHOULD_DISPLAY_BOOKING_MANAGEMENT_ALERT: false,
  CHECKOUT_SHOULD_HIDE_ANCILLARIES: false,
  CHECKOUT_SHOULD_HIDE_CART_REWARD: true,
  CHECKOUT_SHOULD_HIDE_PHONE_TOOLTIP: false,
  CHECKOUT_SHOULD_HIDE_TICKET_OVERVIEW_BLOCK: false,
  CHECKOUT_SHOULD_SHOW_PASSENGER_QUESTION_CONTACT_INFORMATION: false,
  CHECKOUT_SHOULD_SHOW_SPECIFIC_TERMS_CONTENT: false,
  CHECKOUT_PASSENGER_SUBHEADER_INFO: "category",
  DISPLAY_NAME: "Busbud",
  DISPLAY_BOOKING_CAROUSEL: false,
  ENABLE_CFAR: false,
  ENABLE_ESIM_ANCILLARY: false,
  ERROR_SHOULD_SHOW_FOOTER_SIMPLIFIED: false,
  GTM_ID: "GTM-NRH5H3",
  HERO_IMAGE: false,
  HIDE_HEADER_CURRENCY_SELECTOR: false,
  HIDE_LOGIN_AND_HELP_BUTTONS: false,
  HIDE_DATE_TAB_PRICE: false,
  HOTJAR_ID: null,
  LANDING_PAGES_SHOULD_SHOW_AFFILIATE_CHECKBOX: false,
  MANDATORY_PHONE_NUMBER: false,
  POWERED_BY_LOGO: true,
  PURCHASE_CONFIRMATION_SHOULD_HIDE_ACCESS_TO_BOOKING_MANAGER: false,
  PURCHASE_CONFIRMATION_SHOULD_SHOW_RETURN_LINK: false,
  PURCHASE_CONFIRMATION_SHOULD_SHOW_TRANSACTION_SUMMARY: true,
  RESULTS_DEPARTURE_CARD: "classic",
  RESULTS_FILTERS_MAX_PRICE: false,
  RESULTS_FILTERS_SHOW_CLASSES: false,
  RESULTS_HIDE_AD_BANNERS: false,
  RESULTS_SHOULD_HIDE_ALERT_DEPARTURE_DURING_THE_NIGHT: true,
  RESULTS_SHOULD_HIDE_SEAT_SELECTION_LEGEND: true,
  RESULTS_SHOULD_HIDE_SEAT_SELECTION_TRIP_SUMMARY: true,
  RESULTS_SHOW_FILTERS: true,
  RESULTS_SHOULD_SHOW_PRICE_PER_PASSENGER: false,
  RESULTS_SHOULD_SHOW_SEAT_SELECTOR_DRAWER: false,
  RESULTS_SORTING_TYPE: "classic",
  RESULTS_TOOLBAR_SHOULD_SHOW_QUICK_FILTER: false,
  SEARCH_FORM_DISABLE_LOCATION_INPUTS_ON_RESULTS: false,
  SEAT_SELECTION_DISPLAY_MULTIPLE_CLASSES: false,
  SEAT_SELECTION_FIRST: false,
  SHOULD_HIDE_MOBILE_APPS_PROMOTION: true,
  TOP_COUNTRIES_LIST_DEFAULT_COUNTRY_CODE: undefined,
  TOP_COUNTRIES_LIST: [
    "CAN",
    "USA",
    "ESP",
    "FRA",
    "GBR",
    "ARG",
    "DEU",
    "BRA",
    "AUS",
    "ITA"
  ],
  TOP_COUNTRIES_LIST_ADD_USER_COUNTRY: true
};

export function MergeWhitelabelConfig(
  whitelabel_settings: PartnerConfig["whitelabel_settings"] = {}
): PartnerConfig["whitelabel_settings"] {
  return {
    napi_hosts: {},
    napi_auth_token: null,
    busbud_checkout: false,
    // make the phone number mandatory on checkout
    mandatory_phone_number: false,
    // TBD
    billing_address_form: false,
    // TBD
    hero_image: false,
    // TBD
    powered_by_logo: true,
    ...whitelabel_settings,
    features_override: {
      ...default_whitelabel_features,
      ...whitelabel_settings.features_override
    }
  };
}
