import React from "react";

import { HorizonProvider, type HorizonProviderProps } from "@busbud/horizon";

import type { BusbudConfig } from "@app/types/app-types";

export const ICON_PROXY_BASE_PATH = "/horizon-assets";
const ICON_PROXY_SUB_PATH = "/horizon/Icons";
export const PW_S3_ASSETS_DOMAIN_NAME = "busbud-pw-assets.s3.amazonaws.com";

const getSvgBaseURL = (
  environment: BusbudConfig["env"] | null
): string | undefined => {
  switch (environment) {
    case "production":
    case "preview":
    case null:
      return undefined;
    default:
      return `${ICON_PROXY_BASE_PATH}${ICON_PROXY_SUB_PATH}`;
  }
};

interface Props extends React.PropsWithChildren<HorizonProviderProps> {
  environment: BusbudConfig["env"] | null;
}

export const HorizonContextProvider: React.FC<Props> = ({
  environment,
  children
}) => {
  return (
    <HorizonProvider svgBaseURL={getSvgBaseURL(environment)}>
      {children}
    </HorizonProvider>
  );
};
